<template>
	<div class="section_right">
		<!--section_right-->
		<div class="sub_top">
			<!--sub_top-->
			<div class="sub_title">
				<i class="far fa-crown text-primary"></i>
				{{ $t('recom.1') }}
			</div>
		</div>
		<!--sub_top end-->
		<div class="section_right_inner">
			<!--section_right_inner-->
			
			<div class="matrix">
				<div class="w-100 mb-base">
					<div id="tree" class="tree_bg" ></div>
				</div>
			</div>
		</div>
		<!--section_right_inner end-->
	</div>
	<!--section_right end-->
</template>
<script>
const CryptoJS = require("crypto-js");
export default {
	mounted(){
		this.GetRecomList()
	},
	data () {
		return {
			node : []
		}
	},
	methods : {
		GetRecomList(){
			const body = {};
			const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

			this.$http.post(`/member/organization/GetRecomList`,{req}).then(
				res => {
					if(res.status==200){
						if(res.data.code=='200'){
							const e_body = res.data.body;
							const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
							const d_res = bytes.toString(CryptoJS.enc.Utf8);
							const body = JSON.parse(d_res)
							const node = body.node;

							this.node = node;
							this.checkNode()
							
						}else if(res.data.code=='9999'){
							this.$store.dispatch('SETLOGOUT').then(
								()=>{
									window.location.href= `/${this.$i18n.locale}/login`;
								}
							)
						}
					}

				}
			).catch(() => {});
		},
		checkNode(){
           

		   for (var i = 0; i < this.node.length; i++) {
			   var node = this.node[i];
			   switch (node.STAR) {
				   case "0":
					   node.tags = ["STAR_0"];
					   break;
				   case "1":
					   node.tags = ["STAR_1"];
					   break;
				   case "2":
					   node.tags = ["STAR_2"];
					   break;
				   case "3":
					   node.tags = ["STAR_3"];
					   break;
				   case "4":
					   node.tags = ["STAR_4"];
					   break;
					case "5":
					   node.tags = ["STAR_5"];
					   break;
					case "6":
					   node.tags = ["STAR_6"];
					   break;
					case "7":
					   node.tags = ["STAR_7"];
					   break;
			   }
		   }
		//    OrgChart.templates.ana.field_0 = '<text class="field_0"  style="font-size: 14px;" fill="#000000" x="125" y="30" text-anchor="middle">Star : {val} </text>';
		//    OrgChart.templates.ana.field_1 = '<text class="field_1"  style="font-size: 14px;" fill="#000000" x="125" y="50" text-anchor="middle">Id : {val}</text>';
		//    OrgChart.templates.ana.field_2 = '<text class="field_2"  style="font-size: 14px;" fill="#000000" x="125" y="70" text-anchor="middle">Sales : {val}</text>';
		//    OrgChart.templates.ana.field_3 = '<text class="field_3"  style="font-size: 14px;" fill="#000000" x="125" y="90" text-anchor="middle">Today : {val}</text>';

		   
		   OrgChart.templates.isla.field_0 = '<text class="field_0"  style="font-size: 14px;" fill="#000000" x="90" y="83" text-anchor="middle">Star : {val} </text>';
		   OrgChart.templates.isla.field_1 = '<text class="field_1"  style="font-size: 16px; font-weight:600;" fill="#7259c4" x="90" y="60" text-anchor="middle">{val}</text>';
		   OrgChart.templates.isla.field_2 = '<text class="field_2"  style="font-size: 14px;" fill="#000000" x="90" y="103" text-anchor="middle">Sales : {val}</text>';
		   OrgChart.templates.isla.field_3 = '<text class="field_3"  style="font-size: 14px;" fill="#000000" x="90" y="123" text-anchor="middle">Today : {val}</text>';

		   
		   var chart = new OrgChart(document.getElementById("tree"), {
			   nodeBinding: {
				   field_0: "STAR",
				   field_1: "ID",
				   field_2: "MY",
				   field_3: "TODAY",
				   img_0:"img"
			   },
			   showYScroll: true,
					mouseScrool: OrgChart.action.scroll,
					toolbar: {
        zoom: true,
        fit: true,
    },
			   nodes: this.node
		   });

		   this.show = false
	  	}
	},
}
</script>
<style scoped>
.badge{
    background-color: #fff !important;
    box-shadow: 0 0 3px;
}
span{
    margin-right: 8px;
    margin-bottom: 10px;
    display: inline-block;
}
span.badge:last-child{
    margin-right: 0;
}
.theme01 .tree_bg{
	background-color: #363741;
}
</style>
<style >
#tree{
    max-width: 100%;
	height: calc(100vh - 300px);
	
}
    .node.STAR_99 rect {
        fill: #e0e0e0;
        opacity: 0.2; 
    }
    .node.STAR_0 rect {
        fill: #ffffff !important;
        opacity: 0.5;
    }
	/* .theme01 .node.STAR_0 rect{
		fill: #333 !important;
	}
	.theme01 .field_0, .theme01 .field_1, .theme01 .field_2, .theme01 .field_3{
		color: #fff !important;
	} */
    .node.STAR_1 rect {
        fill: #ffa600;
        opacity: 0.1;
    }
    .node.STAR_2 rect {
        fill:#2d7ae2;
        opacity: 0.1;
    }
    .node.STAR_3 rect {
        fill: #12ab8e;
        opacity: 0.1;
    }
    .node.STAR_4 rect {
        fill: #e96065;
        opacity: 0.1;
    }
	.node.STAR_5 rect {
        fill: #e13065;
        opacity: 0.1;
    }
	.node.STAR_6 rect {
        fill: #e13995;
        opacity: 0.1;
    }
	.node.STAR_7 rect {
        fill: #f13900;
        opacity: 0.1;
    }

    .STAR_99{
        color: #e0e0e0;
    }
    .STAR_0 {
        color: #989898;
    }
    .STAR_1 {
        color: #ffa600;
    }
    .STAR_2 {
        color:#2d7ae2;
    }
    .STAR_3 {
        color: #12ab8e;
    }
    .STAR_4 {
        color: #e96065;
    }
    .STAR_5 {
        color: #ffa600;
    }
    .STAR_6 {
        color:#e13995;
    }
    .STAR_7 {
        color: #f13900;
    }


</style>

