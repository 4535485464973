<template>
	<div class="section_right signup">
		<!--section_right-->
		<div class="sub_top">
			<!--sub_top-->
			<div class="sub_title"><i class="far fa-hand-holding-usd text-primary"></i>{{ $t('support.1') }}</div>
		</div>
		<!--sub_top end-->
		<div class="section_right_inner" v-if="show_add ==false">
			<div class="matrix">
				<div class="w-100 mb-base">
					<div id="tree"></div>
				</div>
			</div>
		</div>
		<div v-if="show_add ==true">
			<div class="" style="height:600px">
				<div class="matrix">
					<div class="w-100 mb-base">
						<div class="join_div">
							<div class="index_input">
								<p class="input_title">{{$t("join.9")}}</p>
								<div class="flex-between-center">
									<input type="text" class="index_input01" name="s_username"  :placeholder="`${$t('join.10')}`" v-model="add_id"  autocomplete='off' :disabled="dupl_check">
									<button class="flex-shrink-0 chk_btn" @click="DuplCheck()" v-if="dupl_check ==false">{{$t('join.44')}}</button>
									<button class="flex-shrink-0 chk_btn" @click="ResetId()" v-if="dupl_check ==true">{{$t('join.47')}}</button>
								</div>
							</div>
							<div class="index_input">
								<p class="input_title">{{$t("join.2")}}</p>
								<input type="text" class="index_input01"  :placeholder="`${$t('join.3')}`" name="name" v-model="add_name" autocomplete='off'>
							</div>
							
							<div class="index_input">
								<p class="input_title">{{$t("join.11")}}</p>
								<input type="Password" class="index_input01"  :placeholder="`${$t('join.12')}`" name="passwd" v-model="add_password">
							</div>
							<div class="index_input">
								<p class="input_title">{{$t("join.13")}}</p>
								<input type="Password" class="index_input01"  :placeholder="`${$t('join.14')}`" name="passwd" v-model="add_password_conf">
							</div>
							<div class="index_input">
								<p class="input_title">{{$t("join.43")}}</p>
								<input type="text" class="index_input01"  :placeholder="`${$t('join.12')}`" name="passwd" v-model="parent" disabled>
							</div>
						<div class="index_input fw-normal">
							<p class="input_title">{{$t("join.38")}}</p>
							<fieldset class="border-0 flex-start-center gap-1">
								<div class="w-100 text-center">
									<input type="radio" id="contactChoice1" value="L" v-model="add_line" :disabled="lien_check" class="btn_radio_input d-none"/>
									<label for="contactChoice1" class="fs-px-14 ps-1">{{$t("join.39")}}</label>
								</div>
								<div class="w-100 text-center">
									<input type="radio" id="contactChoice2" value="R" v-model="add_line" style="margin-left:20px;" :disabled="lien_check" class="btn_radio_input d-none"/>
									<label for="contactChoice2" class="fs-px-14 ps-1">{{$t("join.40")}}</label>
								</div>
							</fieldset>		
						</div>
							<div class="index_input mb-0 signup_btn">
								<button class="button02 w-100" @click="AddNode()">{{$t('join.17')}}</button>
							</div>
							<div class="text_btn_box">
								<button class="text_btn" @click="CloseAdd()">{{$t('join.18')}}</button>
							</div>
						</div>
					</div>
				</div>
			</div>
			
		</div>
		
	</div>
	<!--section_right end-->
</template>
<script>
const CryptoJS = require("crypto-js");
export default {
	mounted(){
		this.GetRecomList()
	},
	data () {
		return {
			node : [],
			show_add : false,
			parent : '',
			add_line : '',
			add_name : '',
			add_password : '',
			add_password_conf : '',
			add_id :'',
			dupl_check : false, 
			lien_check : false,
		}
	},
	methods : {
		ResetId(){
			this.dupl_check = false;
			this.add_id = '';
		},
		DuplCheck(){
			const id = this.add_id;

			const body = {id};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

			this.$http.post(`/member/sign/CheckDupl`,{req}).then(
				res => {
					if(res.status==200){
						if(res.data.code=='200'){
							this.$alert(this.$t('join.46'))
							this.dupl_check = true;
						}else{
							this.$alert(this.$t('join.45'))
							this.dupl_check = false;
						}
					}else{
						loader.hide();
					}

				}
			).catch(() => {});
		},
		AddNode(){
			const parent = this.parent;
			const name = this.add_name;
			const id = this.add_id;
			const password = this.add_password;
			const conf_password = this.add_password_conf;
			const line = this.add_line;
			const dupl_check = this.dupl_check;
			if(id ==''){
				this.$alert(this.$t('join.10'));
				return
			}

			if(password =='' ){
				this.$alert(this.$t('join.24'))
				return false;
			}

			if(password.length < 4 ){
				this.$alert(this.$t('join.25'))
				return false;
			}


			if(password != conf_password){
				this.$alert(this.$t('join.26'))
				return false;
			}

			if(line ==''){
				this.$alert(this.$t('join.38'))
				return false;
			}

			if(dupl_check ==false){
				this.$alert(this.$t('join.48'))
				return false;
			}


			const body = {parent,name,id,password,line};
			const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

			this.$http.post(`/member/organization/AddNode`,{req}).then(
				res => {
					if(res.status==200){
						if(res.data.code=='200'){
							this.$alert(this.$t('support.2'))
							this.show_add = false;
							this.GetRecomList()
							
						}else if(res.data.code=='110'){
							this.$alert(this.$t('support.3'))
							// this.show_add = false;
							
						}else if(res.data.code=='9999'){
							this.$store.dispatch('SETLOGOUT').then(
								()=>{
									window.location.href= `/${this.$i18n.locale}/login`;
								}
							)
						}
					}

				}
			).catch(() => {});
		},

		ShowAdd(){
			this.add_name ='';
			this.add_id ='';
			this.add_password ='';
			this.add_password_conf ='';
			this.add_line ='';
			this.dupl_check =false;
			this.show_add = true;
		},
		CloseAdd(){
			this.add_name ='';
			this.add_id ='';
			this.add_password ='';
			this.add_password_conf ='';
			this.add_line ='';
			this.dupl_check =false;
			this.show_add = false;
			this.GetRecomList()
		},
		GetRecomList(){
			const body = {};
			const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

			this.$http.post(`/member/organization/GetSupportList`,{req}).then(
				res => {
					if(res.status==200){
						if(res.data.code=='200'){
							const e_body = res.data.body;
							const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
							const d_res = bytes.toString(CryptoJS.enc.Utf8);
							const body = JSON.parse(d_res)
							const node = body.node;

							this.node = node;
							this.checkNode()
							
						}else if(res.data.code=='9999'){
							this.$store.dispatch('SETLOGOUT').then(
								()=>{
									window.location.href= `/${this.$i18n.locale}/login`;
								}
							)
						}
					}

				}
			).catch(() => {});
		},
		checkNode(){
           

		   for (var i = 0; i < this.node.length; i++) {
			   var node = this.node[i];
			   switch (node.STAR) {
				   case "0":
					   node.tags = ["STAR_0"];
					   break;
				   case "1":
					   node.tags = ["STAR_1"];
					   break;
				   case "2":
					   node.tags = ["STAR_2"];
					   break;
				   case "3":
					   node.tags = ["STAR_3"];
					   break;
				   case "4":
					   node.tags = ["STAR_4"];
					   break;
					case "5":
					   node.tags = ["STAR_5"];
					   break;
					case "6":
					   node.tags = ["STAR_6"];
					   break;
					case "7":
					   node.tags = ["STAR_7"];
					   break;
			   }
		   }
		//    OrgChart.templates.ana.field_0 = '<circle r="10" cx="10" cy="10" class="{val}"/>';
		//    OrgChart.templates.ana.field_1 = '<text class="field_1"  style="font-size: 14px;" fill="#000000" x="125" y="30" text-anchor="middle">Star : {val} </text>';
		//    OrgChart.templates.ana.field_2 = '<text class="field_2"  style="font-size: 14px;" fill="#000000" x="125" y="50" text-anchor="middle">Id : {val}</text>';
		//    OrgChart.templates.ana.field_3 = '<text class="field_3"  style="font-size: 14px;" fill="#000000" x="125" y="70" text-anchor="middle">Sales : {val}</text>';
		//    OrgChart.templates.ana.field_4 = '<text class="field_4"  style="font-size: 14px;" fill="#000000" x="125" y="90" text-anchor="middle">Today : {val}</text>';

		   window.OrgChart.templates.isla.field_0 = '<circle r="10" cx="10" cy="10" class="{val}"/>';
		   window.OrgChart.templates.isla.field_1 = '<text class="field_1"  style="font-size: 14px;" fill="#000000" x="90" y="85" text-anchor="middle">Star : {val} </text>';
		   window.OrgChart.templates.isla.field_2 = '<text class="field_2"  style="font-size: 16px; font-weight:600;" fill="#7259c4" x="90" y="60" text-anchor="middle">{val}</text>';
		   window.OrgChart.templates.isla.field_3 = '<text class="field_3"  style="font-size: 14px;" fill="#000000" x="90" y="105" text-anchor="middle">Sales : {val}</text>';
		   window.OrgChart.templates.isla.field_4 = '<text class="field_4"  style="font-size: 14px;" fill="#000000" x="90" y="125" text-anchor="middle">Today : {val}</text>';

			new window.OrgChart(document.getElementById("tree"), {
			    nodeBinding: {
				   img_0:"img",
					field_0:"LINE",
				   field_1: "STAR",
				   field_2: "ID",
				   field_3: "MY",
				   field_4: "TODAY"
			   },
			   nodeMenu: {
					add: {text:"ADD", onClick: (args)=>{
						this.parent = args;
						this.ShowAdd()
					}}
				},
			   nodes: this.node,
			        showYScroll: true,
					mouseScrool: OrgChart.action.scroll,
					toolbar: {
        zoom: true,
        fit: true,
    },
		   });

		   this.show = false
	  	}
	},
}
</script>
<style scoped>
.badge{
    background-color: #fff !important;
    box-shadow: 0 0 3px;
}
span{
    margin-right: 8px;
    margin-bottom: 10px;
    display: inline-block;
}
span.badge:last-child{
    margin-right: 0;
}
.bgwhite{
	background: #fff;
}
</style>
<style >
#tree{
    max-width: 100%;
	height: calc(100vh - 300px);
	
}
    .node.STAR_99 rect {
        fill: #e0e0e0;
        opacity: 0.2; 
    }
    .node.STAR_0 rect {
        fill: #ffffff !important;
        opacity: 0.5;
    }
    .node.STAR_1 rect {
        fill: #ffa600;
        opacity: 0.1;
    }
    .node.STAR_2 rect {
        fill:#2d7ae2;
        opacity: 0.1;
    }
    .node.STAR_3 rect {
        fill: #12ab8e;
        opacity: 0.1;
    }
    .node.STAR_4 rect {
        fill: #e96065;
        opacity: 0.1;
    }
	.node.STAR_5 rect {
        fill: #e13065;
        opacity: 0.1;
    }
	.node.STAR_6 rect {
        fill: #e13995;
        opacity: 0.1;
    }
	.node.STAR_7 rect {
        fill: #f13900;
        opacity: 0.1;
    }

    .STAR_99{
        color: #e0e0e0;
    }
    .STAR_0 {
        color: #989898;
    }
    .STAR_1 {
        color: #ffa600;
    }
    .STAR_2 {
        color:#2d7ae2;
    }
    .STAR_3 {
        color: #12ab8e;
    }
    .STAR_4 {
        color: #e96065;
    }
    .STAR_5 {
        color: #ffa600;
    }
    .STAR_6 {
        color:#e13995;
    }
    .STAR_7 {
        color: #f13900;
    }

	.left {
        fill: rgb(230, 85, 85);
		cx:0;
		cy:20;
    }
	.right {
        fill: rgb(106, 106, 243);
		cy:20;
		cx:180;
    }
</style>

